import { Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ConfigProvider } from 'antd'
import { Provider } from 'react-redux'
import { store } from './redux-store'
import { theme } from './styles/theme'
import AppLoader from './modules/common/components/common/app-loader/app-loader'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ConfigProvider
    // direction='rtl'
    theme={{
      token: theme
    }}>
    <Provider store={store}>
      <Suspense fallback={<AppLoader></AppLoader>}>
        <App />
      </Suspense>
    </Provider>
  </ConfigProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
