import './App.scss'
import { createHashRouter, RouterProvider } from 'react-router-dom'
import UserRoutes from './modules/users/routes'
import DashboardRoutes from './modules/dashboard/routes'
import AppLoader from './modules/common/components/common/app-loader/app-loader'
import { useSelector } from 'react-redux'
import { RootState } from './redux-store'
import { useEffect, useState } from 'react'
import './modules/common/common.scss'
import Strings from './modules/common/services/localization-service'
import { getLanguage } from './modules/common/services/local-storage-service'

function App() {
  // document.title = 'MI Ops'
  const [initLocalization, setInitLocalization] = useState(false)

  useEffect(() => {
    const language = getLanguage() || ''
    Strings.setLanguage(language)
    setInitLocalization(true)
  }, [])

  return (
    <>
      {!initLocalization ? (
        <div>
          <AppLoader />
        </div>
      ) : (
        <AppRouter />
      )}
    </>
  )
}
const router = createHashRouter([...UserRoutes, ...DashboardRoutes])

function AppRouter() {
  const loading = useSelector((state: RootState) => state.loader.loader)
  return (
    <>
      <div>
        {loading && <AppLoader />}
        <RouterProvider router={router} />
      </div>
    </>
  )
}

export default App
