export const policyStrings = {
  en: {
    policyAndAnnouncement: 'Policy and Announcements',
    addNewPolicyAndAnnouncement: 'Add New Policy and Announcements',
    editNewPolicyAndAnnouncement: 'Edit New Policy and Announcements',
    policy: 'Policy',
    announcement: 'Announcement',
    information: 'Information',
    category: 'Category',
    general: 'General',
    publish: 'Publish',
    policyType: 'Policy Type',
    viewPolicy: 'View Policy',
    viewInformation: 'View Information',
    deleteInformation: 'Delete Information',
    deleteAnnouncement: 'Delete Announcement',
    viewAnnouncement: 'View Announcement',
    downloadPolicy: 'Download Policy',
    downloadAnnouncement: 'Download Announcement',
    typeRequired: 'Type is Required!',
    categoryRequired: 'Category is Required!',
    fileRequired: 'File is Required!',
    choiceRequired: 'Please select Yes or No',
    hr: 'HR',
    addPolicySuccess: 'Policy Added Successfully!',
    addInformationSuccess: 'Information Added Successfully!',
    addAnnouncementSuccess: 'Announcement Added Successfully!',
    updatePolicySuccess: 'Policy Updated Successfully',
    updateInformationSuccess: 'Information Updated Successfully',
    updateAnnouncementSuccess: 'Announcement Updated Successfully',
    deletePolicySuccess: 'Policy Deleted Successfully',
    deleteInformationSuccess: 'Information Deleted Successfully',
    deleteAnnouncementSuccess: 'Announcement Deleted Successfully',
    uploadedOn: 'Uploaded On',
    it: 'IT',
    selectType: 'Select Type',
    selectCategory: 'Select Category',
    published: 'Published',
    chooseFile: 'Choose File',
    updatedOn: 'Updated On',
    lastUpdated: 'Last Updated',
    addPolicy: 'Add New Policy',
    editPolicy: 'Edit Policy',
    editInformation: 'Edit Information',
    editAnnouncement: 'Edit Announcement'
  },
  es: {}
}
