import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ICurrentUser {
  email: string
  userId: string
}

export interface IAuthSlice {
  currentUser: ICurrentUser
}

const initialState: IAuthSlice = {
  currentUser: { email: '', userId: '' }
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<any>) => {
      state.currentUser = action.payload
    }
  }
})

export const { setCurrentUser } = authSlice.actions

export default authSlice.reducer
