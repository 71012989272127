export const timesheetStrings = {
  en: {
    timesheet: 'Timesheet Details',
    projectWiseWorkReport: 'Project wise Work details',
    start: 'Start Date',
    end: 'End Date',
    view: 'View',
    download: 'Download',
    selectEmployeePlaceHolder: 'Select Employee',
    addTimesheet: 'Add Timesheet',
    timesheetReport: 'Timesheet Report',
    project: 'Project',
    message: 'Message',
    showReport: 'Show Report',
    unfilledDates: 'Unfilled Dates',
    unfilledDays: 'Unfilled Days',
    totalDays: 'Total Days',
    searchUserPlace: 'Search User Name...',
    fileDownloadSuccess: 'File Downloaded Successfully!',
    date: 'Date',
    descriptions: 'Description',
    startTime: 'Start Time',
    allocationInHours: 'Allocation in Hours',
    totalDurationInHours: 'Total Duration in Hours',
    endTime: 'End Time',
    duration: 'Duration',
    timesheetIgnore: 'Timesheet Ignore People',
    addNew: 'Add new Person',
    addPerson: 'Add Person',
    addTimesheetIgnoreSuccess: 'Person Added Successfully!',
    updateSkillSuccess: 'Person Updated Successfully'
  },
  es: {}
}
