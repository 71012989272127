export const clientStrings = {
  en: {
    client: 'Client',
    clients: 'Clients',
    addClients: 'Add Client',
    title: 'Title',
    name: 'Name',
    email: 'Email',
    gender: 'Gender',
    addClient: 'Add Client',
    updateClient: 'Update Client',
    searchUserPlaceHolder: 'Search User...',
    firstNameRequired: 'First name is required.',
    lastNameRequired: 'Last name is required.',
    noZeroPhone: 'Phone number cannot be all zeros!',
    tenDigineededPhone: 'Phone number must be exactly 10 digits!',
    sameContactNotAllowed: 'Contact numbers cannot be the same!',
    tenDigitPhoneValidation: 'Please enter a valid 10-digit phone number!',
    contactNumber: 'Contact Number',
    phoneRequired: 'Contact Number is required!',
    addClientSuccess: 'Client Added Successfully!',
    clientUpdateSuccess: 'Client Updated Successfully',
    editClient: 'Edit Client',
    onlyCharacter: 'Name should contain only alphabets.',
    titleNoSpace: "Title can't start or end with space",
    projectNoSpace: "Project name can't start or end with space",
    clientNameNoSpace: "Client Name can't start or end with space",
    deleteConfirmationDialogText: 'Are you sure you want to delete this item?',
    add: 'Add',
    update: 'Update'
  },
  es: {}
}
