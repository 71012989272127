export const UserStrings = {
  en: {
    enterFirstName: 'Please input your firstName!',
    ldapusers: 'LDAP Users',
    enterLastName: 'Please input your lastName!',
    firstName: 'First Name',
    lastName: 'Last Name',
    enterEmail: 'Please input your email!',
    submit: 'Submit',
    email: 'Email',
    newPassword: 'New Password',
    oldPassword: 'Old Password',
    newPasswordChangeSuccessfully: 'Password changed successfully',
    enterPhoneNumber: 'Please input your email!',
    resetPasswordChangeSuccessfully:
      'Your password has been reset successfully !',
    phoneNumber: 'Phone Number',
    or: 'Or',
    phoneNum: 'PhoneNumber',
    confirmPassword: 'Confirm Password',
    enterPassword: 'Please input your password!',
    newPasswordConfirm: 'Please confirm your password!',
    newPasswordConfirmErr: 'The two passwords that you entered do not match!',
    enterNewPassword: 'Please input  password!',
    changePassword: 'Change Password',
    password: 'Password',
    loginSuccessfully: 'Logged in successfully',
    userUpdateSuccess: ' User Updated Successfully',
    register: 'Register Now!',
    registerSuccessfully: 'Registration  successfully',
    editProfile: 'Edit Profile',
    editUser: 'Edit User',
    username: 'Username',
    forgotPassword: 'Password reset link has been sent on your email.',
    forgotPass: 'Forgot password?',
    userProfileSuccess: 'Profile updated successfully',
    fileUploadMaxLimitErr: 'Maximum allowed size 10MB',
    fileUploadSuccess: 'File uploaded successfully',
    name: 'Full Name',
    sessionExpired: 'Session Expired',
    logoutImmediately: 'Logout Immediately',
    sessionHasExpiredMessage:
      'Your Session Has expired, you will be redirected to login after after 10 seconds',
    passwordRegError:
      'Password must contain at least one letter and one number and a special character',
    passwordMinLengthError: 'Password must have a minimum length of 8',
    passwordMaxLengthError: 'Password should be less than 30 characters',
    accountEnabled: 'Account Enabled',
    changeLdapPassword: 'Change LDAP Password',
    passwordCriteria:
      'Note: Password must have at least 12 characters including 1 upper case, 1 lower case, 1 digit and 1 symbol from @#$%^&+=.',
    search: 'Search',
    clear: 'Clear',
    addUser: 'Add User',
    cancel: 'Cancel',
    passwordUpdateSuccess: 'New Password updated successfully.',
    userAddedSuccessfully: 'New user was added successfully.'
  },
  es: {
    enterFirstName: '¡Por favor ingrese su nombre!',
    enterLastName: '¡Por favor ingrese su apellido!',
    firstName: 'Primer nombre',
    lastName: 'Apellido',
    enterEmail: '¡Por favor ingrese su correo electrónico!',
    submit: 'Entregar',
    email: 'Correo electrónico',
    newPassword: 'Nueva contraseña',
    oldPassword: 'Contraseña anterior',
    newPasswordChangeSuccessfully: 'Contraseña cambiada con éxito',
    enterPhoneNumber: '¡Por favor ingrese su correo electrónico!',
    resetPasswordChangeSuccessfully:
      '¡Su contraseña ha sido restablecida con éxito!',
    phoneNumber: 'Número de teléfono',
    or: 'O',
    phoneNum: 'Número de teléfono',
    confirmPassword: 'Confirmar contraseña',
    enterPassword: '¡Por favor ingrese su contraseña!',
    newPasswordConfirm: '¡Por favor, confirme su contraseña!',
    newPasswordConfirmErr: '¡Las dos contraseñas que ingresó no coinciden!',
    enterNewPassword: '¡Por favor ingrese la contraseña!',
    changePassword: 'Cambia la contraseña',
    password: 'Contraseña',
    loginSuccessfully: 'Iniciado sesión con éxito',
    register: '¡Regístrate ahora!',
    registerSuccessfully: 'Registro exitoso',
    editProfile: 'Editar perfil',
    forgotPassword:
      'El enlace de restablecimiento de contraseña se ha enviado a su correo electrónico.',
    forgotPass: '¿Se te olvidó tu contraseña?',
    userProfileSuccess: 'perfil actualizado con éxito',
    fileUploadMaxLimitErr: 'Tamaño máximo permitido 10 MB',
    fileUploadSuccess: 'El archivo ha subido correctamente',
    name: 'Nombre completo',
    sessionExpired: 'Sesión expirada',
    logoutImmediately: 'Cerrar sesión inmediatamente',
    sessionHasExpiredMessage:
      'Su sesión ha expirado, será redirigido para iniciar sesión después de 10 segundos',
    passwordRegError:
      'La contraseña debe contener al menos una letra y un número y un carácter especial',
    passwordMinLengthError: 'La contraseña debe tener una longitud mínima de 8',
    passwordMaxLengthError: 'La contraseña debe tener menos de 30 caracteres',
    userUpdateSuccess: 'Usuario actualizada con éxito',
    editUser: 'editar usuario',
    username: 'Nombre de usuario'
  }
  /*
   * We can add multiple languages like this
   * fr: {
   *  enterEmail: 'Veuillez saisir votre e-mail !',
   *  email: 'e-mail'
   * } */
}
