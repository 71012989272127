import ProtectedRoute from '../users/components/protected-route/protected-route'
import { lazy } from 'react'
const Dashboard = lazy(() => import('./pages/dashboard/dashboard'))

const routes = [
  {
    path: '/*',
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    )
  }
]

export const ALL_TIMESHEET_RECORDS_ROUTE = '/all-timesheet-records'
export default routes
