import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface IApiError {
  apiError: string
  generalError: string
  isReceived: boolean
}

const initialState: IApiError = {
  apiError: '',
  generalError: '',
  isReceived: false
}

export const apiErrorSlice = createSlice({
  name: 'apiError',
  initialState,
  reducers: {
    setApiError: (state, action: PayloadAction<string>) => {
      state.apiError = action.payload
    },
    setGeneralError: (state, action: PayloadAction<string>) => {
      state.generalError = action.payload
    },
    setIsReceived: (state, action: PayloadAction<boolean>) => {
      state.isReceived = action.payload
    }
  }
})

export const { setApiError, setGeneralError, setIsReceived } =
  apiErrorSlice.actions

export default apiErrorSlice.reducer
