import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ILDAPUser } from '../types/ILDAPUser'

export interface IUserState {
  LDAPUserProfile: ILDAPUser | null
}

const initialState: IUserState = {
  LDAPUserProfile: null
}

export const LDAPUsersSlice = createSlice({
  name: 'LDAPUser',
  initialState,
  reducers: {
    setLDAPUserProfile: (state, action: PayloadAction<ILDAPUser>) => {
      state.LDAPUserProfile = action.payload
    }
  }
})

export const { setLDAPUserProfile } = LDAPUsersSlice.actions

export default LDAPUsersSlice.reducer
