export const projectStrings = {
  en: {
    addProject: 'Add Project',
    editProject: 'Edit Project',
    updateProject: 'Update Project',
    manageProject: 'Manage Projects',
    projectType: 'Project Type',
    projectCount: 'Project Count',
    projectRegion: 'Project Region',
    projectEngagementModel: 'Project Engagement Model',
    searchProManPlaceholder: 'Search by Project or Manager...',
    hoursConsumed: 'Total Hours Consumed',
    manager: 'Manager',
    projectStatus: 'Project Status',
    expectedHours: 'Expected Hours',
    expectedDate: 'Expected Date',
    status: 'Status',
    projectName: 'Project Name',
    stalled: 'Stalled',
    finished: 'Finished',
    onSchedule: 'On Schedule',
    behindSchedule: 'Behind Schedule',
    intermittent: 'Intermittent',
    selectClient: 'Select a Client',
    addProjectSuccess: 'Project Added Successfully!',
    editProjectSuccess: 'Project Updated Successfully!',
    exportcsv: 'Export CSV',
    upcomingMilestone: 'Upcoming Milestone',
    notes: 'Notes',
    noteNoSpace: "Notes can't start or end with space",
    report: 'Report',
    projectCost: 'Project Cost',
    projectSummary: 'Project Summary',
    manageConsultants: 'Manage Consultants',
    addEmployeeWorkDetail: 'Add Employee Work Detail',
    projectNotes: 'Project Notes',
    selectFile: 'Select File',
    addProjectEmpSuccess: 'Employee Added Successfully!',
    editProjectEmpSuccess: 'Employee Edited Successfully!',
    addProjectConSuccess: 'Consultant Added Successfully!',
    updateProjectEmpSuccess: 'Employee Updated Successfully!',
    updateProjectConSuccess: 'Consultant Updated Successfully!',
    updateNotesSuccess: 'Notes  Updated Successfully!',
    addNotesSuccess: 'Notes  Added Successfully!',
    updateMilestoneSuccess: 'Milestone Updated Successfully!',
    addMilestoneSuccess: 'Milestone Added Successfully!'
  },
  es: {}
}
