import { IDownloadUrlResponse } from '../types/IDownloadUrlResponse'
import {
  IFileCreateTokenRequest,
  IFileCustomRequest,
  IFileRequest,
  IUploadFileRequest,
  IUploadURLRequest
} from '../types/IFileRequest'
import { IFileTokenResponse, s3UrlResponse } from '../types/IFileResponse'
import { getApiClientWithAuth } from './api-service'

export const createFileToken = async (
  fileReq: IFileCreateTokenRequest
): Promise<IFileTokenResponse> => {
  try {
    const response = await getApiClientWithAuth().post(
      '/api/v1/files/create-token',
      fileReq
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const getS3Url = async (
  uploadFileReq: IUploadURLRequest
): Promise<s3UrlResponse> => {
  try {
    const response = await getApiClientWithAuth().post(
      '/api/v1/upload-urls',
      uploadFileReq
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const getDownloadUrl = async (
  entityName: string
): Promise<IDownloadUrlResponse> => {
  try {
    const response = await getApiClientWithAuth().get(
      `/api/v1/files/${entityName}/download-url`
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const uploadMedia = async (url: string, files: IFileRequest) => {
  try {
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', files?.type)
    const file = files
    const requestOptions: RequestInit = {
      method: 'PUT',
      headers: myHeaders,
      body: file as BodyInit,
      redirect: 'follow'
    }
    const response = await fetch(url, requestOptions)
    return response
  } catch (error) {
    throw error
  }
}

export const uploadUrl = async (fileData: IUploadFileRequest) => {
  try {
    const response = await getApiClientWithAuth().post(
      '/api/v1/files',
      fileData
    )
    return response.data
  } catch (error) {
    throw error
  }
}

export const fileUpload = async (fileData: IFileCustomRequest) => {
  try {
    const formData = new FormData()
    // formData.append('files', fileData as unknown as Blob)
    formData.append('files', fileData as unknown as Blob)
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    // return post(url, formData, config)

    const response = await getApiClientWithAuth().post(
      '/api/v1/files/upload',
      formData,
      config
    )
    return response.data
  } catch (error) {
    throw error
  }
}
