export const interviewStrings = {
  en: {
    manageexitInterview: 'Manage Exit Interview',
    fillExitBtn: 'Fill Out Exit Interview Form',
    roles: 'Roles',
    employeeNo: 'Employee No',
    adminStatus: 'Admin Status',
    admin: 'Admin',
    viewExitDetails: 'View Exit Interview Details',
    fillForm: 'Fill Form',
    editForm: 'Edit Form',
    exitInterview: 'Exit Interview',
    auth: 'Auth',
    complete: 'Complete',
    pending: 'Pending',
    male: 'Male',
    formFilledSuccess: 'Form Filled Successfully!',
    formUpdatedSuccess: 'Form Updated Successfully!',
    employeeIdNoSpace: "Employee ID can't start or end with space",
    firstNameNoSpace: "First Name can't start or end with space",
    lastNameNoSpace: "Last Name can't start or end with space",
    bloodgroupNoSpace: "Blood Group can't start or end with space",
    skypeContactNoSpace: "Skype Contact can't start or end with space",
    sittingFloorNoSpace: "Sitting Floor can't start or end with space",
    currentAddressNoSpace: "Current Address can't start or end with space",
    female: 'Female',
    placeholderSkillEmp: 'Search by Employee or Skill...',
    searchPlaceholder: 'Search...',
    placeholderEmp: 'Search by Employee...',
    empName: 'Employee Name',
    empNumber: 'Employee Number',
    personalEmail: 'Personal email ID',
    selectOptions: 'Select Options',
    skills: 'Skills',
    edit: 'Edit',
    department: 'Department',
    yourAnswer: 'Your Answer',
    workForMobi: 'Would you work for Mobisoft again?',
    recommendToFriend: 'Would you recommend Mobisoft to friends or family?',
    bestInCompany: 'What did you like best about your job?',
    worstInCompany: 'What did you dislike about your job?',
    reasonForLeaving: 'What is your main reason for leaving?',
    improveInJob:
      'What improvements would you suggest about your job/working conditions/management support?',
    miplExitForm: 'MIPL Exit Form',
    miplEditExitForm: 'Edit MIPL Exit Form',
    workHours: 'Work Hours',
    jobResp: 'Job responsibilities',
    workEnv: 'Work environment',
    pay: 'Pay',
    comments: 'Any other comments?',
    betterThere: 'What does your new employer offer different to this company?',
    benefits: 'Benefits',
    jobRating: 'How would you rate the following?'
  },
  es: {}
}
