import { ReactElement, useEffect } from 'react'
import {
  getSessionToken,
  saveSessionToken,
  setLocalStorage
} from '../../../common/services/local-storage-service'

import { LOGIN_DETAILS, LOGOUT_URL } from '../../../common/common-constants'
import { apiBaseUrl } from '../../../common/services/api-service'
import { useDispatch } from 'react-redux'
import { exchangeToken } from '../../../common/services/session-service'
import { setCurrentUser } from '../../../common/slices/auth-slice'
import {
  setApiError,
  setIsReceived
} from '../../../common/slices/api-error-slice'
import { setLDAPUserProfile } from '../../slices/LDAPUser-slice'

const preferAdminRoleIfPresent = (roles: any) => {
  if (roles.length === 0) {
    return ''
  }

  if (roles.length === 1) {
    return roles[0]
  }

  let roleToReturn = ''
  for (let i = 0; i < roles.length; i++) {
    const role = roles[i]
    if (role.role === 'ADMIN') {
      return role
    } else {
      roleToReturn = role
    }
  }
  return roleToReturn
}
interface IProtectedRouteProps {
  children: ReactElement
}

const ProtectedRoute = ({ children }: IProtectedRouteProps) => {
  const dispatch = useDispatch()

  useEffect(() => {
    const url = new URL(window.location.href)

    let t = url.searchParams.get('t')

    if (!t) {
      if (!getSessionToken()) {
        window.location.href = apiBaseUrl() + LOGOUT_URL + getSessionToken()
      }

      return
    }
    if (t.endsWith('/')) {
      t = t.substring(0, t.length - 1)
    }

    if (!getSessionToken()) {
      exchangeToken(t)
        .then((response) => {
          if (response && response.apiToken && response.apiToken.id) {
            saveSessionToken(response.apiToken.id)

            const fullName =
              (response.user.firstName ? response.user.firstName : '') +
              (response.user.lastName ? ' ' + response.user.lastName : '')
            const currentRole =
              response.user.roles && response.user.roles.length > 0
                ? preferAdminRoleIfPresent(response.user.roles)
                : ''
            setLocalStorage(
              LOGIN_DETAILS,
              JSON.stringify({
                userName: response.user.ldapUserName,
                token: response.apiToken.id,
                userId: response.apiToken.userId,
                email: response.user.email,
                fullName: fullName,
                role: currentRole,
                exitId: response.userEndDate
              })
            )
            dispatch(
              setCurrentUser({
                currentUser: {
                  email: response.user.email,
                  userId: response.apiToken.userId,
                  fullName: fullName,
                  role: currentRole
                }
              })
            )
          }
          if (response) {
            dispatch(setLDAPUserProfile(response?.user))
          }
        })
        .catch((error) => {
          dispatch(
            setApiError(JSON.stringify(error.response)) && setIsReceived(true)
          )
        })
    }
  }, [])

  // useEffect(() => {
  //   if (!authToken) {
  //     window.location.href = apiBaseUrl() + LOGOUT_URL + getSessionToken()
  //   }
  // }, [])

  return children
}

export default ProtectedRoute
