export const leavesAndAttendanceStrings = {
  en: {
    holidayList: 'Holiday List',
    leaveCalendar: 'Leave Calendar',
    year: 'Year',
    day: 'Day',
    toDate: 'To Date',
    days: 'Days',
    leaveStatus: 'Leave Status',
    leaveType: 'Leave Type',
    showHoliday: 'SHOW HOLIDAYS',
    addNewHoliday: 'Add New Holiday',
    editHoliday: 'Edit Holiday',
    selectHolidayTypePlace: 'Select Holiday Type',
    occasion: 'Occasion',
    holidayType: 'Holiday Type',
    addHolidaySuccess: 'Holiday Added Successfully!',
    updateHolidaySuccess: 'Holiday Updated Successfully'
  },
  es: {}
}
