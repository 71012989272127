import { RcFile } from 'antd/es/upload'
import { AxiosError } from 'axios'
import moment from 'moment'
import { SyntheticEvent } from 'react'
import {
  FILE_UPLOAD_MAX_SIZE,
  IMAGE_UPLOAD_MAX_SIZE,
  MESSAGE_TYPE_ERROR
} from '../common-constants'
import { showToastMessage } from '../components/common/toast-message/toast-message'
import Strings from '../services/localization-service'
import { IErrorResponse } from '../types/IErrorResponse'
import { IPagination } from '../types/IPagination'
import { ITablePagination } from '../types/ITablePagination'
import CryptoJS from 'crypto-js'
import {
  createFileToken,
  getS3Url,
  uploadMedia,
  uploadUrl
} from '../services/file-service'
import { IFileRequest } from '../types/IFileRequest'
import config from '../../../config'

export function getErrorMessage(error: AxiosError<IErrorResponse> | Error) {
  let msg = Strings.commonErrorMessage

  if (error && error instanceof AxiosError) {
    if (error.response && error.response.data && error.response.data.message) {
      msg = error.response.data.message
    } else if (
      error.response &&
      error.response.data &&
      error.response.data.errors &&
      error.response.data.errors.general.length > 0
    ) {
      msg = error.response.data.errors.general[0].message
    }
  } else if (error && error.message) {
    msg = error.message
  }

  return msg
}

export const imageTypeSizeValidation = (file: RcFile, accept: string[]) => {
  const isJpgOrPng =
    accept.filter((val) => val.includes(file.type))?.length > 0 ||
    (!file.type && file.name.toLowerCase().endsWith('.heif'))

  if (!isJpgOrPng) {
    showToastMessage(MESSAGE_TYPE_ERROR, Strings.uploadMsg)
  }
  const isLt5MB = file.size <= IMAGE_UPLOAD_MAX_SIZE
  if (!isLt5MB) {
    showToastMessage(MESSAGE_TYPE_ERROR, Strings.imageUploadMaxLimitErr)
  }
  return isJpgOrPng && isLt5MB
}
export const getTablePagination = (
  pagination: IPagination | null | undefined
) => {
  let currentPage = 1

  if (pagination && pagination.offset !== 0) {
    currentPage = Math.round(pagination.offset / pagination?.limit) + 1
  }

  return {
    current: currentPage,
    pageSize: pagination?.limit || 50,
    total: (pagination && pagination.totalRecords) || 10,
    pageSizeOptions: ['50', '100', '150'],
    showSizeChanger: true,
    lastUpdatedId: pagination?.lastUpdatedId
  }
}

export const getTablePaginationAllocation = (
  pagination: IPagination | null | undefined
) => {
  let currentPage = 1

  if (pagination && pagination.offset !== 0) {
    currentPage = Math.round(pagination.offset / pagination?.limit) + 1
  }

  return {
    current: currentPage,
    pageSize: pagination?.limit || 1000,
    total: (pagination && pagination.totalRecords) || 1000,
    pageSizeOptions: ['1000', '1500', '2000'],
    showSizeChanger: true,
    lastUpdatedId: pagination?.lastUpdatedId
  }
}

export const getOffset = (pagination: ITablePagination) => {
  if (pagination.current && pagination.pageSize) {
    return (pagination.current - 1) * pagination.pageSize
  }

  return 0
}

export const getSortDir = (sortDirection: string) => {
  if (sortDirection === 'ascend') {
    return 'asc'
  } else {
    return 'desc'
  }
}

export const getListQuery = (
  pagination: ITablePagination,
  searchText: string | undefined
) => {
  let query = ''

  if (pagination) {
    query += `?offset=${getOffset(pagination)}&limit=${pagination.pageSize}`

    if (searchText) {
      query += `&search=${searchText}`
    }
    if (pagination.columnKey && pagination.order) {
      query += `&sortColumn=${pagination.columnKey}`
      query += `&sortType=${getSortDir(pagination.order)}`
    }
  }
  return query
}

export const getDataAfterSearch = (searchText: string | undefined) => {
  let query = ''
  if (searchText) {
    query += `&search=${searchText}`
  }

  return query
}

export const getFileFromEvent = (event: SyntheticEvent) => {
  const target = event.target as HTMLInputElement
  return target?.files && target.files.length > 0 && target.files[0]
}

export const getPhoneNumberWithCountryCode = (
  phoneNumber: string,
  countryCode: string
) => {
  return `${countryCode}-${phoneNumber}`
}

export const getPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.split('-')[1]
}

export const getCountryCode = (phoneNumber: string) => {
  return phoneNumber.split('-')[0]
}

export const validateEmail = (email: string) => {
  const emailRegex = /\S+@\S+\.\S+/
  return emailRegex.test(email)
}

export const getFormattedDate = (date: string, format = 'DD MMM YYYY') => {
  return date && moment(date).format(format)
}

export const getArrayFromCommaSeparated = (
  commaSeparatedString: string
): string[] => {
  if (!commaSeparatedString) {
    return []
  }
  return commaSeparatedString.split(',')
}

export const getCommaSeparatedString = (arr: string[]) => {
  if (!arr) {
    return ''
  }
  return arr.join(',')
}

export const getValueFromEvent = (event: SyntheticEvent) => {
  const target = event.target as HTMLInputElement
  return target.value
}

export const convertArrayToString = (inputArray: string) => {
  return [inputArray].join(',')
}

export const isPhoneType = (fieldKey: string) => {
  return (
    fieldKey.toLowerCase().includes('phone') ||
    fieldKey.toLowerCase().includes('mobile')
  )
}

export const fileTypeSizeValidation = (file: RcFile, accept: string) => {
  const allowedType = accept.split(',')
  const fileExtension = file.name.split('.').pop() || ''
  const isAllowedType = allowedType.includes(`.${fileExtension}`)
  // console.log('fileExtension', fileExtension)

  if (!isAllowedType) {
    showToastMessage(
      MESSAGE_TYPE_ERROR,
      `You can only upload ${allowedType.join('/').toLocaleUpperCase()} file!`
    )
  }
  const isLt10MB = file.size <= FILE_UPLOAD_MAX_SIZE
  if (!isLt10MB) {
    showToastMessage(MESSAGE_TYPE_ERROR, Strings.fileUploadMaxLimitErr)
  }
  return isAllowedType && isLt10MB
}

export const encryptToken = (token: string) => {
  return CryptoJS.AES.encrypt(token, config.app.secretKey).toString()
}

export const decryptToken = (encryptedToken: string) => {
  const bytes = CryptoJS.AES.decrypt(encryptedToken, config.app.secretKey)
  return bytes.toString(CryptoJS.enc.Utf8)
}

export const uploadFileWithToken = async (
  file: IFileRequest,
  purposeObjectId: string,
  purpose: string,
  tag?: string
) => {
  if (file.name) {
    const tokenResp = await createFileToken({ fileName: file.name })
    if (tokenResp && tokenResp?.token) {
      const resp = await getS3Url({
        fileName: file.name,
        token: JSON.stringify(encryptToken(tokenResp.token))
      })
      if (resp && resp.uploadUrl) {
        const imageUploadResp = await uploadMedia(resp.uploadUrl, file)
        if (imageUploadResp && purposeObjectId) {
          const data = {
            refObjectId: resp.refObjectId,
            purposeObjectId: purposeObjectId,
            purpose: purpose || 'profile',
            tag: tag
          }
          const respUrl = await uploadUrl(data)
          return respUrl
        }
      }
    }
  }
  return ''
}

export function shouldSubmit(event: any) {
  return event !== undefined && event.keyCode === 13
}
