import { IExchangeTokenResponse } from '../types/ITokenExchangeResponse'
import { getApiClientWithAuth } from './api-service'

export const exchangeToken = async (
  exchangeToken: string
): Promise<IExchangeTokenResponse> => {
  try {
    const response = await getApiClientWithAuth().post(
      '/api/v1/session/exchange-token',
      { exchangeToken }
    )

    return response.data
  } catch (error) {
    throw error
  }
}
