// accessSlice.js

import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { User } from '../types/IAccesses'

export interface IUserState {
  accessProfile: User | null
  // accessProfile: any
}

const initialState: IUserState = {
  accessProfile: null
}

const accessesSlice = createSlice({
  name: 'accesses',
  initialState, // Initial state can be an empty array or an object depending on your needs
  reducers: {
    setAccesses: (state, action: PayloadAction<User>) => {
      state.accessProfile = action.payload
      //   return action.payload // Set the entire array received from the API as the new state
    }
  }
})

export const { setAccesses } = accessesSlice.actions

export default accessesSlice.reducer
