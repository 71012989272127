export const externalAppsStrings = {
  en: {
    addNewExternalApp: 'Add New External App',
    editExternalApp: 'Edit External App',
    externalApp: 'External App',
    link: 'Link',
    descriptionTitle: 'Description',
    appNameRequired: 'App Name is Required!',
    linkRequired: 'URL is Required!',
    validUrl: 'Please enter a valid URL!',
    descriptionRequired: 'Description is Required!',
    appNameNoSpace: "App Name can't start or end with space",
    descriptionNoSpace: "Description can't start or end with space",
    descriptionMaxThousand: 'Description cannot exceed 1000 characters',
    addEAppSuccess: 'External App Added Successfully!',
    updateEAppSuccess: 'External App Updated Successfully'
  },
  es: {}
}
