import config from '../../../../config'
import { useSearchParams } from 'react-router-dom'
import { FC, ReactElement, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { apiBaseUrl } from '../../../common/services/api-service'
import { LOGIN_DETAILS, LOGOUT_URL } from '../../../common/common-constants'
import {
  getSessionToken,
  saveSessionToken,
  setLocalStorage
} from '../../../common/services/local-storage-service'
import { exchangeToken } from '../../../common/services/session-service'
import {
  setApiError,
  setIsReceived
} from '../../../common/slices/api-error-slice'
import { setCurrentUser } from '../../../common/slices/auth-slice'
import { useNavigate } from 'react-router-dom'
import { setAccesses } from '../../../common/slices/access-slice'

const preferAdminRoleIfPresent = (roles: any) => {
  if (roles.length === 0) {
    return ''
  }

  if (roles.length === 1) {
    return roles[0]
  }

  let roleToReturn = ''
  for (let i = 0; i < roles.length; i++) {
    const role = roles[i]
    if (role.role === 'ADMIN') {
      return role
    } else {
      roleToReturn = role
    }
  }

  return roleToReturn
}

const OAuthLogin: FC = (): ReactElement => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    async function doLogin() {
      let token = searchParams.get('t') || ''

      if (!token) {
        window.location.href = apiBaseUrl() + LOGOUT_URL + getSessionToken()
        if (!getSessionToken()) {
          window.location.replace(config.app.baseUrl + '/oauth-login')
        }
      }

      if (token.endsWith('/')) {
        token = token.substring(0, token.length - 1)
      }

      // if (!getSessionToken()) {
      if (token) {
        try {
          const response = await exchangeToken(token)

          // console.log(response)
          if (response && response.apiToken && response.apiToken.id) {
            saveSessionToken(response.apiToken.id)
            dispatch(setAccesses(response.user))

            const fullName =
              (response.user.firstName ? response.user.firstName : '') +
              (response.user.lastName ? ' ' + response.user.lastName : '')

            const currentRole =
              response.user.roles && response.user.roles.length > 0
                ? preferAdminRoleIfPresent(response.user.roles)
                : ''

            setLocalStorage(
              LOGIN_DETAILS,
              JSON.stringify({
                userName: response.user.ldapUserName,
                token: response.apiToken.id,
                userId: response.apiToken.userId,
                email: response.user.email,
                fullName: fullName,
                role: currentRole,
                exitId: response.userEndDate
              })
            )
            dispatch(
              setCurrentUser({
                currentUser: {
                  email: response.user.email,
                  userId: response.apiToken.userId,
                  fullName: fullName,
                  role: currentRole
                }
              })
            )
          }
          navigate('/')
        } catch (error) {
          dispatch(setApiError(JSON.stringify(error)) && setIsReceived(true))
        }
      }
    }

    doLogin()
  }, [])
  return <div></div>
}

export default OAuthLogin
// **************************************************************************
// import config from '../../../../config'
// import { useSearchParams } from 'react-router-dom'
// import { FC, ReactElement, useEffect } from 'react'
// import { useDispatch } from 'react-redux'
// import { apiBaseUrl } from '../../../common/services/api-service'
// import { LOGIN_DETAILS, LOGOUT_URL } from '../../../common/common-constants'
// import {
//   getSessionToken,
//   saveSessionToken,
//   setLocalStorage
// } from '../../../common/services/local-storage-service'
// import { exchangeToken } from '../../../common/services/session-service'
// import {
//   setApiError,
//   setIsReceived
// } from '../../../common/slices/api-error-slice'
// import { setCurrentUser } from '../../../common/slices/auth-slice'
// import { useNavigate } from 'react-router-dom'

// const preferAdminRoleIfPresent = (roles: any) => {
//   if (roles.length === 0) {
//     return ''
//   }

//   if (roles.length === 1) {
//     return roles[0]
//   }

//   let roleToReturn = ''
//   for (let i = 0; i < roles.length; i++) {
//     const role = roles[i]
//     if (role.role === 'ADMIN') {
//       return role
//     } else {
//       roleToReturn = role
//     }
//   }

//   return roleToReturn
// }

// const OAuthLogin: FC = (): ReactElement => {
//   const dispatch = useDispatch()
//   const navigate = useNavigate()
//   const [searchParams] = useSearchParams()

//   useEffect(() => {
//     let token = searchParams.get('t') || ''

//     if (!token) {
//       window.location.href = apiBaseUrl() + LOGOUT_URL + getSessionToken()
//       if (!getSessionToken()) {
//         window.location.replace(config.app.baseUrl + '/oauth-login')
//       }
//     }

//     if (token.endsWith('/')) {
//       token = token.substring(0, token.length - 1)
//     }

//     // if (!getSessionToken()) {
//     if (token) {
//       exchangeToken(token)
//         .then((response) => {
//           if (response && response.apiToken && response.apiToken.id) {
//             saveSessionToken(response.apiToken.id)

//             const fullName =
//               (response.user.firstName ? response.user.firstName : '') +
//               (response.user.lastName ? ' ' + response.user.lastName : '')

//             const currentRole =
//               response.user.roles && response.user.roles.length > 0
//                 ? preferAdminRoleIfPresent(response.user.roles)
//                 : ''

//             setLocalStorage(
//               LOGIN_DETAILS,
//               JSON.stringify({
//                 userName: response.user.ldapUserName,
//                 token: response.apiToken.id,
//                 userId: response.apiToken.userId,
//                 email: response.user.email,
//                 fullName: fullName,
//                 role: currentRole
//               })
//             )
//             dispatch(
//               setCurrentUser({
//                 currentUser: {
//                   email: response.user.email,
//                   userId: response.apiToken.userId,
//                   fullName: fullName,
//                   role: currentRole
//                 }
//               })
//             )
//             //////////////////
//             // redirect('/')
//             // navigate('/')
//           }
//           navigate('/')
//           // redirect('/')
//         })
//         .catch((error) => {
//           dispatch(
//             setApiError(JSON.stringify(error.response)) && setIsReceived(true)
//           )
//         })
//     }

//     // if (t) {
//     //   navigate('/')
//     // }
//   }, [])
//   return <div></div>
// }

// export default OAuthLogin
