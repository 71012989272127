import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface ISkillState {
  skillIds: string
}
const initialState: ISkillState = {
  skillIds: ''
}

export const skillSlice = createSlice({
  name: 'skillIds',
  initialState,
  reducers: {
    setSkills: (state, action: PayloadAction<string>) => {
      state.skillIds = action.payload
    }
  }
})

export const { setSkills } = skillSlice.actions

export default skillSlice.reducer
