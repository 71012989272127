export const employeesStrings = {
  en: {
    empId: 'Employee ID',
    employees: 'Employees',
    employee: 'Employee',
    addEmployees: 'Add Employee',
    editEmployees: 'Edit Employee',
    updateEmployees: 'Update Employee',
    lastDateHeader: 'Set Last Date of Employee',
    selectEndDatePlace: 'Select End Date',
    employeeWorkDetailHeader: 'Employee Work Detail Form',
    dateOfBirth: 'Date of birth',
    bloodGroup: 'Blood Group',
    skypeContact: 'Skype Contact',
    currentAddress: 'Current Address',
    email: 'Email',
    dailyHour: 'Daily Hours',
    enterNumberPlaceHolder: 'Please enter a number!',
    tentativeReleaseDate: 'Tentative Release Date',
    fromDate: 'From Date',
    contactNo1: 'Contact No-1',
    contactNo2: 'Contact No-2',
    joiningDate: 'Joining Date',
    action: 'Action',
    gender: 'Gender',
    select: 'Select',
    skill: 'Skill',
    designation: 'Designation',
    contactNo: 'Contact No',
    resignationDate: 'Resignation Date',
    projectManager: 'Project Manager',
    contractor: 'Contractor',
    onRoll: 'on Roll',
    access: 'Access',
    taskTitle: 'Task Title',
    type: 'Type',
    region: 'Region',
    engagementModel: 'Engagement Model',
    reportingManager: 'Reporting Manager',
    projectStartDate: 'Project Start Date',
    deliveryManager: 'Delivery Manager',
    techLead: 'Tech. Lead',
    qaLead: 'QA Lead',
    profileImage: 'Profile Image',
    addEmployeeSuccess: 'Employee Added Successfully!',
    lastDateSuccess: 'Last date added Successfully!',
    editEmployeeSuccess: 'Employee Updated Successfully!',
    workDetail: 'Work Details',
    employeeId: 'Employee Id',
    employeeName: 'Employee Name',
    contact: 'Contact',
    fill30Days:
      'Do you want the Employee to fill the timesheet  for the past 30 days?',
    projectTitle: 'Project Title',
    sittingFloor: 'Sitting Floor',
    forgetID: 'Forget ID Card',
    selectDate: 'Select Date',
    tempIDno: 'Temp. Id Card No.',
    empAllocation: 'Employees Allocation'
  },
  es: {}
}
