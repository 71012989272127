export const settingsStrings = {
  en: {
    skills: 'Skills',
    save: 'Save',
    addSkillSuccess: 'Skill Added Successfully!',
    updateSkillSuccess: 'Skill Updated Successfully',
    designation: 'Designation',
    skillNoSpace: "Skill can't start or end with space",
    designationNoSpace: "Designation can't start or end with space",
    occassionNoSpace: "Occassion can't start or end with space",
    adddesignationSuccess: 'Designation Added Successfully!',
    updateDesignationSuccess: 'Designation Updated Successfully'
  },
  es: {}
}
