import { configureStore } from '@reduxjs/toolkit'
import userReducer from './modules/users/slices/user-slice'
import loaderReducer from './modules/common/slices/loader-slice'
import languageSlice from './modules/common/slices/language-slice'
import sidebarReducer from './modules/dashboard/slices/sidebar-slice'
import apiErrorSlice from './modules/common/slices/api-error-slice'
import authSlice from './modules/common/slices/auth-slice'
import LDAPUserReducer from './modules/users/slices/LDAPUser-slice'
import employeeSlice from './modules/employees/slices/employee-slice'
import accessesSlice from './modules/common/slices/access-slice'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import skillSlice from './modules/employees/slices/skill-slice'

const persistConfig = {
  key: 'root',
  storage
}
const persistedAccessReducer = persistReducer(persistConfig, accessesSlice)

export const store = configureStore({
  reducer: {
    users: userReducer,
    loader: loaderReducer,
    language: languageSlice,
    sidebar: sidebarReducer,
    apiErrorSlice: apiErrorSlice,
    authSlice: authSlice,
    LDAPUsersSlice: LDAPUserReducer,
    employeeSlice: employeeSlice,
    skillSlice: skillSlice,
    accessesSlice: persistedAccessReducer
  }
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
