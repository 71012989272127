import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ILDAPUser } from '../../users/types/ILDAPUser'

export interface IUserState {
  userProfile: ILDAPUser | null
}

const initialState: IUserState = {
  userProfile: null
}

export const employeeSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUserProfile: (state, action: PayloadAction<ILDAPUser>) => {
      state.userProfile = action.payload
    }
  }
})

export const { setUserProfile } = employeeSlice.actions

export default employeeSlice.reducer
