import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ILanguageList } from '../types/ILanguageResponse'

export interface ILanguageState {
  defaultLanguage: string
  languageList: ILanguageList[]
}

const initialState: ILanguageState = {
  defaultLanguage: '',
  languageList: []
}

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setDefaultLanguage: (state, action: PayloadAction<string>) => {
      state.defaultLanguage = action.payload
    },
    setLanguageList: (state, action: PayloadAction<ILanguageList[]>) => {
      state.languageList = action.payload
    }
  }
})

export const { setDefaultLanguage, setLanguageList } = languageSlice.actions

export default languageSlice.reducer
