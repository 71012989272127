import { lazy } from 'react'
import OAuthLogin from './pages/oauth-login/oauth-login'
const ForgotPassword = lazy(
  () => import('./pages/forgot-password/forgot-password')
)
// const Login = lazy(() => import('./pages/login/login'))
const Register = lazy(() => import('./pages/register/register'))
export const LOGIN_ROUTE = '/login'
export const REGISTER_ROUTE = '/register'
export const USERS_ROUTE = '/users'
export const FORGOT_PASSWORD_ROUTE = '/forgot-password'
export const RESET_PASSWORD_ROUTE = '/password-reset'
export const USER_LIST_ROUTE = '/view-users'
export const CHANGE_LDAP_PASSWORD_ROUTE = '/change-ldap-password/:id'

const routes = [
  {
    path: '/login',
    element: <OAuthLogin />
  },
  {
    path: '/register',
    element: <Register />
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />
  }
]

export default routes
