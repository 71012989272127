export function saveSessionToken(token: string) {
  localStorage.setItem('SESSION_TOKEN', token)
}

export function getSessionToken(): string | null {
  return localStorage.getItem('SESSION_TOKEN')
}

export function setLocalStorage(itemKey: string, itemValue: string) {
  localStorage.setItem(itemKey, itemValue)
}

export function getSessionRefreshToken(): string | null {
  return localStorage.getItem('REFRESH_TOKEN')
}

export function getLocalStorage(itemKey: string) {
  return localStorage.getItem(itemKey)
    ? JSON.parse(localStorage.getItem(itemKey) || '')
    : null
}

export function saveSessionRefreshToken(token: string) {
  return localStorage.setItem('REFRESH_TOKEN', token)
}

export function saveUserId(userId: string) {
  return localStorage.setItem('USER_ID', userId)
}

export function getUserId() {
  return localStorage.getItem('USER_ID')
}

export function clearStorage() {
  localStorage.clear()
  window.location.reload()
  sessionStorage.clear()
}
export function getEntityData() {
  return localStorage.getItem('ENTITY_DATA')
}

export function setLanguage(languageValue: string) {
  return localStorage.setItem('LANGUAGE', languageValue)
}

export function getLanguage() {
  return localStorage.getItem('LANGUAGE')
}

export function setExitInterviewId(exitId: string) {
  return localStorage.setItem('EXIT_ID', exitId)
}

export function getExitInterviewId() {
  return localStorage.getItem('EXIT_ID')
}
