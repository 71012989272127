import { message } from 'antd'
import './toast-message.scss'
import { CloseOutlined, CheckOutlined } from '@ant-design/icons'
import {
  MESSAGE_TYPE_ERROR,
  MESSAGE_TYPE_SUCCESS
} from '../../../common-constants'
import Strings from '../../../services/localization-service'

// export const showToastMessage = (type: string, messageText: string) => {
//   switch (type) {
//     case MESSAGE_TYPE_SUCCESS:
//       message.success({
//         content: messageText,
//         duration: 3,
//         className: 'toast-area',
//         icon: (
//           <div className='message-header'>
//             <CheckOutlined style={{ color: 'white' }} />
//             <span className='message-label'>{Strings.success}</span>
//           </div>
//         )
//       })
//       break
//     case MESSAGE_TYPE_ERROR:
//       message.error({
//         content: messageText,
//         duration: 3,
//         className: 'toast-area',
//         icon: (
//           <div className='message-header'>
//             <CloseOutlined style={{ color: 'white' }} />
//             <span className='message-label'>{Strings.error}</span>
//           </div>
//         )
//       })
//       break
//   }
// }

export const showToastMessage = (type: string, messageText: string) => {
  switch (type) {
    case MESSAGE_TYPE_SUCCESS:
      message.success({
        content: (
          <div>
            <div className='message-header'>
              {/* <CheckOutlined style={{ color: 'white' }} /> */}
              <span className='message-label'>{Strings.success}</span>
            </div>
            <div>{messageText}</div>
          </div>
        ),
        duration: 3,
        className: 'toast-area'
      })
      break
    case MESSAGE_TYPE_ERROR:
      message.error({
        content: (
          <div>
            <div className='message-header'>
              <span className='message-label'>{Strings.error}</span>
              {/* <CloseOutlined style={{ color: 'white' }} /> */}
            </div>
            <div>{messageText}</div>
          </div>
        ),
        duration: 3,
        className: 'toast-area'
      })
      break
  }
}
